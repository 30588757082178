import React from 'react';
import logo from './logo.svg';
import './App.css';
import { Button } from 'antd';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Something React-y is happening
        </p>
        <p>
          In the meantime, please use the angular version..
        </p>
        <Button type="primary" href="https://davidjoudrey.co.uk" target="_blank">
        Go To Angular Version
      </Button>
      </header>
    </div>
  );
}

export default App;
